<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>Прокси-сервера</h2>
        <search :query.sync="query" @search="search" />
        <el-table ref="table" :data="items">
          <el-table-column label="Название сервера">
            <template slot-scope="scope">
              <router-link class="link" :to="{ name: 'servers:edit', params: { id: scope.row.id } }">
                {{ scope.row.name }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="domain_name" label="Домен" />
          <el-table-column prop="server_id" label="ID сервера" />
          <el-table-column label="Количество камер / Занято / Свободно" align="center">
            <template slot-scope="scope">
              {{ scope.row.cameras_count }} / {{ scope.row.current_cameras_count }} /
              {{ scope.row.freely_cameras_count }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Дата создания" />
          <el-table-column prop="is_managed_by_avc_panel" label="Панель управления" align="center" width="120" >
            <template slot-scope="scope">
              <span>
                {{ scope.row.is_managed_by_avc_panel ? "AVC" : "WMS" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Статус API" width="120" align="center">
            <template slot-scope="scope">
              <i v-if="scope.row.status === 0" class="el-icon-error" />
              <i v-if="scope.row.status === 1" class="el-icon-success" />
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="list__pagination"
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          :current-page="page"
          @current-change="changePage"
        />
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button
        class="page__active_button"
        icon="el-icon-plus"
        type="primary"
        @click="$router.push({ name: 'servers:create' })"
      >
        Добавить
      </el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import routeList from "@/core/mixins/routeList";
import Search from "@/components/Search";

export default {
  name: "Servers",
  components: {
    Search,
  },
  mixins: [routeList],
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapState("servers", {
      items: (state) => state.servers.data,
      total: (state) => state.servers.total,
      count: (state) => state.servers.count,
    }),
    ...mapGetters("servers", ["getQuery"]),
  },
  methods: {
    ...mapActions("servers", {
      getList: "GET_SERVERS",
    }),
    ...mapMutations("servers", {
      setQuery: "SET_QUERY",
    }),
  },
};
</script>

<style></style>
